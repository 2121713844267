<template>
  <div class="p-4 bg-white rounded-lg" v-if="isDataLoaded">
    <DataTable
      :table_state="table_state"
      :store_names="store_names"
      :addComponent="componentForAdding"
      :editComponent="componentForEditing"
      :delete_url="delete_url"
    />
  </div>
</template>

<script>
import DataTable from "@/own/components/DataTable.vue";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/courierAllocationConfigurations.module";
import ActionsDialog from "@/own/components/shipping/courierAllocationConfigurations/ActionsDialog.vue";

export default {
  name: "CourierAllocationConfigurations",
  components: { DataTable },
  data: () => ({
    componentForEditing: ActionsDialog,
    componentForAdding: ActionsDialog,
    delete_url: `${process.env.VUE_APP_BASE_URL}/shipping/courier_allocation_configurations/destroy`,
    table_state: {
      UPDATE_TABLE_STRUCTURE: UPDATE_TABLE_STRUCTURE,
      UPDATE_TABLE_DATA: UPDATE_TABLE_DATA,
      SET_TABLE_SORTBY: SET_TABLE_SORTBY,
      SET_TABLE_FILTER: SET_TABLE_FILTER,
      SET_TABLE_PERPAGE: SET_TABLE_PERPAGE,
      SET_TABLE_PAGE: SET_TABLE_PAGE,
    },
    store_names: {
      getTableFilters: "getCOURIERALLOCATIONCONFIGTableFilters",
      getTableState: "getCOURIERALLOCATIONCONFIGTableState",
      getTableData: "getCOURIERALLOCATIONCONFIGTableData",
      getTableHeaders: "getCOURIERALLOCATIONCONFIGTableHeaders",
      getTableProperties: "getCOURIERALLOCATIONCONFIGTableProperties",
      getTablePermissions: "getCOURIERALLOCATIONCONFIGTablePermissions",
    },
  }),
  methods: {
    async initTableContent() {
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_STRUCTURE, {});
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {});
    },
  },
  computed: {
    isDataLoaded: function () {
      return this.$store.getters[this.store_names.getTableData];
    },
  },
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
    this.initTableContent();
  },
};
</script>
